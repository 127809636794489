html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Sanomat Sans Text Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1em;
  color: #24282c;
  background-color: transparent!important;
}